@tailwind base;
* {
  scrollbar-color: #303030 #131313 ;
}
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/gilroy-medium.ttf) format('truetype');
  }
}

@layer utilities {
  .border-gradient {
    position: relative;
    border: 0.7px solid transparent;
  }
  
  .border-gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 1px; 
    background: linear-gradient(96.78deg, #527FF7 7.62%, #B251ED 28.91%, #F53E8E 72%, #FD6C5D 109.01%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  .border-gradient-2 {
    position: relative;
    border: 0.7px solid transparent;
  }
  
  .border-gradient-2::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 3px; 
    background: linear-gradient(147.9deg, #554EBA 1.09%, #CD4FCF 99.38%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141129;
  font-family: "GilroyMedium";
  
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
